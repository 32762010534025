import {
  getPianoDebugMode,
  getPianoEnv,
  hasInstitutionalAccess,
  pianoActivePurchase,
} from "@sciam/piano";
import { loadExperience, setCustomVariables, setPaywallExempt } from "@sciam/piano/experience";
import { EcommStateTracker } from "@sciam/shared";
import * as Sentry from "@sentry/react";

import { createSessionLog } from "~lib/session-log";
import { watchPianoCookies } from "./debug";

/**
 * Determine if this term is for a Trial
 * @param {string} termId
 * @returns {boolean}
 */
function isTrial(termId) {
  // All Trial terms, both Sandbox and Prod
  const terms = ["TMP4C89FDJQ8", "TM6XHODW4W7C"];
  return terms.includes(termId);
}

export function setupPianoQueue() {
  const tp = window.tp;
  const pianoEnv = getPianoEnv();

  /** Log Piano cookies and changes to Sentry */
  let cookieWatcher;
  try {
    const cookieCrumbs = createSessionLog("piano-cookie-breadcrumbs");
    Sentry.addBreadcrumb({
      type: "debug",
      category: "piano.cookie",
      message: "Breadcrumbs from session storage",
      data: cookieCrumbs.log,
    });

    cookieWatcher = watchPianoCookies(onCookieChange, { initial: cookieCrumbs.log.state });

    /** @type {Parameters<typeof watchPianoCookies>[0]} */
    function onCookieChange(cookies, diff) {
      console.log("[piano] cookie update", cookies, diff);
      cookieCrumbs.add(diff, cookies);

      Sentry.addBreadcrumb({
        type: "debug",
        category: "piano.cookie",
        message: "Cookie change detected",
        data: {
          cookies,
          diff,
        },
      });
    }
    console.log("[piano] cookie monitoring", cookieWatcher, cookieCrumbs);
  } catch (error) {
    console.error("[piano] error setting up cookie watcher", error);
  }

  // Nooo stubs for Composer-based paywall
  window.showEmailCollector = () => console.debug("[piano] composer card: showEmailCollector");
  window.showCurrentOffer = () => console.debug("[piano] composer card: showCurrentOffer");

  tp.push(["setCustomVariable", "platform", "hopper"]);
  tp.push(["setDebug", getPianoDebugMode() === "log"]);
  tp.push(["setPageURL", `${location.protocol}//${location.host}${location.pathname}`]);

  const isContentPaywallExempt =
    window.dataLayer.find((obj) => obj.content)?.content?.paywallExempt || false;
  const isInstitutionalAccessUser = hasInstitutionalAccess();
  const ecommStateTracker = new EcommStateTracker();

  loadExperience(pianoEnv, window.location.hostname);
  // setIframeDomain(window.location.hostname);
  setCustomVariables(window.__DATA__.initialData?.dataLayerContent || {});

  if (isContentPaywallExempt || isInstitutionalAccessUser) {
    setPaywallExempt();
  }

  /**
   * Bind all Piano data events
   */

  tp.push([
    "addHandler",
    "loginSuccess",
    async function (data) {
      window.dataLayer.push({
        event: "login",
        user: {
          isSiteLicenseCustomer: isInstitutionalAccessUser,
          userId: data.params.uid || "",
          loginSource: data.source,
          loginMethod: data.registration ? "registration" : "login",
        },
      });

      // Check whether the user is still logged in after 2 seconds
      setTimeout(() => {
        const user = window.tp?.pianoId?.getUser();
        if (!user) {
          Sentry.captureMessage("User logged out immediately after login", {
            level: "fatal",
            extra: {
              data,
            },
          });
        }
      }, 2000);

      cookieWatcher?.update();
    },
  ]);

  tp.push([
    "addHandler",
    "loginFailed",
    function (data) {
      Sentry.captureMessage("User login failed", {
        level: "warning",
        extra: {
          data,
        },
      });

      cookieWatcher?.update();
    },
  ]);

  tp.push([
    "addHandler",
    "logout",
    function () {
      // If the modal is open on logout, reload to avoid
      // showing an Offers page, which we never want to show the user.
      if (document.body.classList.contains("tp-modal-open")) {
        document.querySelector(".tp-modal")?.remove();
        window.location.reload();
      }

      window.dataLayer.push({
        event: "logout",
      });

      cookieWatcher?.update();
    },
  ]);

  tp.push([
    "addHandler",
    "registrationSuccess",
    function (data) {
      window.dataLayer.push({
        event: "sign_up",
        user: {
          userId: data.user.sub || "",
        },
      });

      cookieWatcher?.update();
    },
  ]);

  /**
   * Push ecommerce data to datalayer
   * https://docs.piano.io/callbacks/#completeevent
   */
  tp.push([
    "addHandler",
    "checkoutComplete",
    function (conversion) {
      ecommStateTracker.setCoupon(conversion.promotionId);
      ecommStateTracker.pushDataLayer("purchase");

      // For Segment et al
      window.dataLayer.push({
        event: "checkoutComplete",
        checkout: {
          isTrial: isTrial(conversion.termId),
          amount: conversion.chargeAmount /* Number: The amount the user was charged */,
          currencyCode:
            conversion.chargeCurrency /* String: The type of currency used (e.g. "USD") */,
          expirationTime:
            conversion.expires /* Number: Timestamp of the access expiration, UNIX timestamp format */,
          promotionId:
            conversion.promotionId /* String: If a promo code was used, this will be the ID of promotion */,
          resourceId: conversion.rid /* String: The resource ID */,
          startTime: conversion.startAt /* String: When access started, ISO-8861 format */,
          subscriptionType: conversion.termId /* String: The term ID - subscription type */,
          uid: conversion.uid /* String: The user ID */,
        },
      });

      pianoActivePurchase.end();
      ecommStateTracker.reset(); // Clear state after purchase

      cookieWatcher?.update();
    },
  ]);

  tp.push([
    "addHandler",
    "checkoutError",
    function () {
      // If the checkout fails, its okay to show the paywall again
      pianoActivePurchase.end();

      cookieWatcher?.update();
    },
  ]);

  tp.push([
    "addHandler",
    "checkoutPaymentError",
    function () {
      // @WARNING: Not a documented event.
      // Fires when a card is rejected.
      pianoActivePurchase.end();

      cookieWatcher?.update();
    },
  ]);

  tp.push([
    "addHandler",
    "checkoutClose",
    function (event) {
      // Fire different events based on the type of close
      switch (event.state) {
        case "checkoutCompleted":
          /* User completed the purchase and now has access */
          window.dataLayer.push({
            event: "checkoutModalClosed_checkoutCompleted",
          });

          // Redirect/refresh as recommended by Piano docs
          // https://docs.piano.io/faq-article/how-to-redirect-users-back-to-the-article-after-checkout/
          var url_params = new URLSearchParams(location.search);

          /* 1. Check for redirect param */
          if (url_params.has("redirect")) {
            window.location.pathname = url_params.get("redirect") || "/";
            break;
          }

          /* 2. Check the page referrer */
          if (
            referrer /* check if referrer exists */ &&
            referrer.hostname === location.hostname /* check if referrer is on the same domain */ &&
            referrer.pathname.includes(
              "/article/",
            ) /* if the reader came from an article page, try to return them */
          ) {
            window.location.href = document.referrer;
          } else {
            window.location = "/";
          }

          break;
        case "alreadyHasAccess":
          /* User already has access */
          window.dataLayer.push({
            event: "checkoutModalClosed_userAlreadyHasAccess",
          });

          /* Redirect/refresh as recommended by Piano docs */
          window.location.pathname = "/account/";
          break;
        case "voucherRedemptionCompleted":
          /* User redeemed a gift voucher
           * Normally gift redemption happens on a landing page,
           * so logically it makes sense to redirect user to a home page after this
           */
          window.dataLayer.push({
            event: "checkoutModalClosed_voucherRedemptionCompleted",
          });

          /* Redirect/refresh, eliminating params (in case of gifting) */
          window.location.href = "/account/";
          break;
        case "close":
          /* User did not complete the purchase and simply closed the modal */
          window.dataLayer.push({
            event: "checkoutModalClosed_userClosedModal",
          });
      }

      cookieWatcher?.update();
    },
  ]);

  /**
   * User has selected a term, beginning the checkout flow
   * https://docs.piano.io/callbacks/#selecttermevent
   */
  tp.push([
    "addHandler",
    "checkoutSelectTerm",
    function (termDetails) {
      const tp = window.tp;
      const userObj = tp.pianoId.getUser();

      ecommStateTracker.setTerm(termDetails.termId, termDetails.termName);
      ecommStateTracker.pushDataLayer("select_item");

      window.dataLayer.push({
        event: "checkoutUserSelectTerm",
        user: {
          userId: userObj?.uid || "",
        },
        checkout: {
          isTrial: isTrial(termDetails.termId),
          subscriptionType: termDetails.termId,
          subscriptionName: termDetails.termName,
          resourceId: termDetails.resourceId,
          resourceName: termDetails.resourceName,
        },
      });

      cookieWatcher?.update();
    },
  ]);

  /**
   * User has moved past auth flow into checkout flow.
   *
   * https://docs.piano.io/callbacks/#startevent
   */
  tp.push([
    "addHandler",
    "startCheckout",
    function (data) {
      const tp = window.tp;
      const userObj = tp.pianoId.getUser();

      window.dataLayer.push({
        event: "checkoutStartCheckout",
        user: {
          userId: userObj?.uid || "",
        },
        checkout: {
          isTrial: isTrial(data.termId),
          subscriptionType: data.termId,
          offerId: data.offerId,
        },
      });

      cookieWatcher?.update();
    },
  ]);

  /**
   * Send datalayer event information when the user submits payment
   * https://docs.piano.io/callbacks/#submitpayment
   */
  tp.push([
    "addHandler",
    "submitPayment",
    function (data) {
      const tp = window.tp;
      const userObj = tp.pianoId.getUser();

      ecommStateTracker.setPrice(data.term.chargeAmount);
      ecommStateTracker.pushDataLayer("add_payment_info");

      /* Hide our trial modal after checkout until we have reliable sub info. */
      pianoActivePurchase.start();

      window.dataLayer.push({
        event: "checkoutSubmitPayment",
        user: {
          userId: userObj?.uid || "",
        },
        checkout: {
          isTrial: isTrial(data.term.termId),
          offerId: data.offerId,
          currencyCode: data.term.chargeCurrency,
          description: data.term.description,
          amount: data.term.chargeAmount,
          subscriptionType: data.term.termId,
          subscriptionName: data.term.name,
          totalAmount: data.term.totalAmount,
          sku: data.term.sku,
          resourceId: data.term.resource.rid,
          resourceName: data.term.resource.name,
          resourceDescription: data.term.resource.description,
          termType: data.term.type,
          taxRate: data.term.taxRate,
          taxAmount: data.term.taxAmount,
        },
      });

      cookieWatcher?.update();
    },
  ]);

  /**
   * Send datalayer event information when the user interacts with checkout
   * https://docs.piano.io/callbacks/#statechangeevent
   */
  tp.push([
    "addHandler",
    "checkoutStateChange",
    function (event) {
      const tp = window.tp;

      const pianoUserObj = tp.pianoId.getUser();
      // Returns true or false: https://docs.piano.io/piano-id-functions/
      const isUserLoggedIn = tp.pianoId.isUserValid();

      /* @HACK: Fire a datalayer call when a CDS lookup occurs, i.e. external account link submitted */
      if (window.location.pathname === "/account/link/" && event.stateName === "receipt") {
        window.dataLayer.push({
          event: "cds_account_registration",
          user: {
            userId: isUserLoggedIn ? pianoUserObj.uid : "",
          },
        });
      }

      switch (event.stateName) {
        case "state2":
          // Payment details page.
          //
          //  Note: event.term.chargeAmount is not a documented object property in Piano's docs for
          //    this callback, but we want to use it for GA ecomm.
          //    If the price suddenly stops being reported, check if this property is still valid.
          ecommStateTracker.setPrice(event.term.chargeAmount);
          ecommStateTracker.pushDataLayer("begin_checkout");
          break;
      }

      cookieWatcher?.update();
    },
  ]);
}
