import { pushDataLayer } from "~lib/analytics/datalayer";

/**
 * Custom tracking events for analytics.
 */

/**
 * We're _pretty sure_ the user has spent a decent
 * amount of time on this page _or_ found something they want to see.
 */
function bindEngagementEvent() {
  let metScrollCriteria = false;
  let metTimeCriteria = false;

  let recordedEvent = false;

  // Engaged based on time and scroll criteria
  function handleOneCriteriaMet() {
    if (!recordedEvent && metScrollCriteria && metTimeCriteria) {
      console.debug("[page engagement] all criteria met");
      pushDataLayer({ event: "user_engaged" });
      recordedEvent = true;
    }
  }

  // Engaged based on Internal click criteria
  // Reader is on a page and found something else that interests them after at least 10s
  setTimeout(() => {
    window.addEventListener("click", (e) => {
      const isInternalLink = e.target
        ?.closest("a[href]")
        ?.href.startsWith(`${window.location.protocol}//${window.location.host}`);

      if (!recordedEvent && isInternalLink) {
        console.debug("[page engagement] internal click");
        pushDataLayer({ event: "user_engaged" });
        recordedEvent = true;
      }
    });
  }, 10000);

  window.addEventListener(
    "scroll",
    (e) => {
      if (!metScrollCriteria && Math.min(window.innerHeight, 1000)) {
        metScrollCriteria = true;
        handleOneCriteriaMet();
      }
    },
    { passive: true },
  );

  let seconds = 0;
  let timer = setInterval(() => {
    // Only run the clock when the tab is in view
    if (!document.hidden) {
      seconds++;
    }

    if (seconds >= 20) {
      metTimeCriteria = true;
      handleOneCriteriaMet();
      clearInterval(timer);
    }
  }, 1000);
}

export default function setupCustomEvents() {
  bindEngagementEvent();
}
