import { Auth0Provider } from "@auth0/auth0-react";
import { usePage } from "~core/hooks/use-page";
import { useAuth0Env } from "~features/auth/hooks/use-auth0-env";
import { onPianoAuthSyncEvent } from '~features/piano/sync-utils';

export const ProvideAuth0 = ({ children }) => {
  // This can be injected at the router level
  const { pageData } = usePage();
  const { returnTo } = pageData?.props || {};

  /**
   * Get the Auth0 app config, which is determined by either the PUBLIC_AUTH0_APP env variable
   * or the user's current flags (e.g. ?flag=auth0:staging)
   */
  const auth0App = useAuth0Env();

  return (
    <Auth0Provider
      // Config variables for Access Code Flow with PKCE
      domain={auth0App.domain}
      clientId={auth0App.clientId}
      // Cache the user's session in localstorage for better UX
      cacheLocation="localstorage"
      // Use refresh tokens to keep the user logged in
      useRefreshTokens={true}
      // Default parameters for the Auth0 /authorize url
      authorizationParams={{
        audience: auth0App.apiAudience,
        scope: "openid profile email",

        // Default page to redirect to after login
        redirect_uri:
          // Use current page's origin
          ((typeof window !== "undefined" && window.location.origin) ||
            // Fallback for SSR
            import.meta.env.BASE_URL) +
          // Default to the callback route
          "/callback/",
      }}
      // This handler is called after the user logs in or signs up
      // More specifically, it runs when a page url has a code and state parameter
      onRedirectCallback={(appState, user) => {
        // Remove the code and state parameters from the URL
        window.history.replaceState({}, document.title, window.location.pathname);

        console.log("onRedirectCallback", appState, user);

        const returnToPath = appState?.returnTo || returnTo?.path;
        // If there's no returnTo URL provided, do nothing
        // Also prevents accidental circular redirects
        if (!returnToPath || returnToPath === window.location.pathname) return;

        // Redirect to the returnTo URL
        let done;
        const returnToLastPage = () => {
          if (done) return;
          done = true;
          window.location.href = returnToPath;
        };

        // We don't want to redirect immediately
        // Instead we wait for a new token to be set for Piano Auth Sync (see ~/features/piano/auth-sync.js)
        onPianoAuthSyncEvent(returnToLastPage);

        // As a fallback, force redirect after 5 seconds
        // This will mean the user's Piano JWT will be out of sync with the Auth0 session
        // Consequently, the JWT sync will be triggered on the next page load.
        setTimeout(returnToLastPage, 5000);
      }}
      children={children}
    />
  );
};

export default ProvideAuth0;
