import * as Sentry from "@sentry/react";

export default async function setupSentry() {
  const dl = window.__DATA__.initialData?.dataLayerContent?.content || {};

  Sentry.init({
    // Disabled in vite dev server and when no release key is present
    enabled: !!(__CMD__ === "build" && __SENTRY_ENV__ && __SENTRY_RELEASE__),
    dsn: "https://b87d0ffe9ef64395a204270e6b6a3289@o173313.ingest.sentry.io/4504878123188224",
    environment: __SENTRY_ENV__,
    release: __SENTRY_RELEASE__,
    enableTracing: true,
    sampleRate: 0.01,
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.01,
    // Sentry recommends keeping this at 1.0 in production
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      // ... Object: This is caused by Microsoft's Safe Link crawling our site, not a real error
      // ... undefined: Entirely devoid of context, most likely ad-related
      /Non-Error promise rejection captured with value: (Object|undefined)/,

      // > 20% of these are from a browser called Yeti, a crawler for South Korean search engine Naver
      // A significant portion of the rest seem to be Yahoo Japan and pre-rendered (e.g. Pocket)
      "Event `Event` (type=error) captured as promise rejection",
    ],
    beforeSend(event) {
      // Check for errors from ads/gpt
      const banned = /gpt|pagead|goog|doublecl/i;
      if (
        event.exception?.values?.some(
          (exception) =>
            exception.value?.match(banned) ||
            exception.stacktrace?.frames?.some((frame = {}) => frame.filename?.match(banned))
        )
      ) {
        return null;
      }

      return event;
    },
    beforeBreadcrumb(breadcrumb) {
      // Piano logs are noisy and generally irrelevant to Sentry's purpose
      if (breadcrumb.category === "console" && breadcrumb.message?.startsWith("TP:")) {
        return null;
      }
      return breadcrumb;
    },
    // Only whitelist scripts from our origin and sciam domains
    allowUrls: [new RegExp(`^${window.location.origin}/`), /scientificamerican\.com/, /sciam\.com/],
    initialScope: {
      tags: {
        bundle: window.__DATA__.bundle,
        contentType: dl.type,
        contentfulId: dl.contentfulId,
      },
    },

    integrations: [
      new Sentry.BrowserTracing(),
      // new Sentry.BrowserProfilingIntegration(),
      new Sentry.Replay()
    ],
  });
}
